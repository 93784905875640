<!--Inspired by Timeline, Kelsey Santangelo @ https://codepen.io/ksantangelo/pen/ZBOpoJ/-->
<template>
  <section class="timeline">
    <div class="entry">
      <div class="title">
        <h3>2018 - 2023</h3>
        <p>NUS High School of Mathematics & Science, Singapore</p>
      </div>
      <div class="body">
        <p>Graduating With Honours in Chemistry and Mathematics, and Majors in Computer Science and Physics</p>
        <ul>
          <li>Skipped a year for both chemistry and mathematics</li>
          <li>Attended Olympiad lessons for biology, chemistry, physics, and math</li>
          <li>Realised eventually that chemistry and computer science are the superior sciences</li>
          <li>
            <div class="outer">
              <input id="more" type="checkbox"/>
              <div class="inner">
                <ul>
                  <li class="list">
                    <p>2021</p>
                    <ul>
                      <li>Engineering Good Tech For Good Festival Finalist</li>
                      <li>Singapore Junior Chemistry Olympiad Gold Award</li>
                      <li>Singapore Junior Physics Olympiad (General Round) Bronze Award</li>
                      <li>Singapore Junior Physics Olympiad (Special Round) Silver Award</li>
                      <li>Singapore Mathematical Olympiad (Senior) Silver Award</li>
                    </ul>
                  </li>
                  <li class="list">
                    <p>2020</p>
                    <ul class="list">
                      <li>National Science Challenge Champion Team</li>
                      <li>Singapore Junior Physics Olympiad Gold Award</li>
                      <li>United Kingdom Biology Challenge Gold Award</li>
                      <li>A*STAR Science Award (Upper Secondary)</li>
                    </ul>
                  </li>
                  <li class="list">
                    <p>2019</p>
                    <ul class="list">
                      <li>Singapore Mathematical Olympiad (Junior) Top 30 Individual Ranking</li>
                      <li>Singapore Junior Chemistry Olympiad Gold Award</li>
                      <li>Singapore Junior Biology Olympiad Gold Award</li>
                      <li>United Kingdom Biology Challenge Gold Award</li>
                      <li>NUS High Best in Physics Book Prize</li>
                    </ul>
                  </li>
                  <li class="list">
                    <p>2018</p>
                    <ul class="list">
                      <li>Singapore Mathematical Olympiad (Junior) Gold Award</li>
                      <li>Singapore Junior Physics Olympiad Honourable Mention</li>
                    </ul>
                  </li>
                  <li class="list">
                    <p>2017</p>
                    <ul class="list">
                      <li>RHB-The Straits Times National Spelling Championship 2nd Runner-Up</li>
                    </ul>
                  </li>
                  <li class="list">
                    <p>2016</p>
                    <ul class="list">
                      <li>International Mathematics & Science Olympiad (Science Team) Gold Award</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <label for="more">Competitive Achievements </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="entry">
      <div class="title">
        <h3>2021 - 2022</h3>
        <p>National University of Singapore (NUS)</p>
      </div>
      <div class="body">
        <p>Non-Graduating Programme (NUS High School Advanced Placement Programme)</p>
        <ul>
          <li>
            Taking three modules as part of the Honours programme for chemistry and mathematics
            <ul class="list">
              <li>CM1102: Chemistry - The Central Science</li>
              <li>CM2133: Foundations of Physical Chemistry</li>
              <li>MA2002: Calculus</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="entry">
      <div class="title">
        <h3>Present</h3>
        <p>Various MOOC platforms</p>
      </div>
      <div class="body">
        <p>Multiple Courses of Different Disciplines</p>
        <ul>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="entry">
      <div class="title">
        <h3>2018 - 202X</h3>
        <p>Ministry of Education Language Centre (MOELC), Singapore</p>
      </div>
      <div class="body">
        <p>French as a Third Language</p>
        <ul>
          <li>Working towards a Diploma of French Language Studies (DELF) B2 standard</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Portfolio"
}
</script>

<style lang="scss" scoped>
$gutter: 30px;
$border-width: 4px;
$dot-diameter: 8px;

* {
  box-sizing: border-box;
}

.timeline {
  width: 100%;
  max-width: 800px;
  padding: 100px 50px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(33% + 15px);
    bottom: 0;
    width: $border-width;
    background: rgba(2, 10, 85, 0.25);
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.entry {
  clear: both;
  text-align: left;
  position: relative;

  .title {
    margin-bottom: .5em;
    float: left;
    width: 33%;
    padding-right: $gutter;
    //padding-left: 5px;
    //border-left: 10px solid #E59E4B;
    text-align: right;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: $dot-diameter;
      height: $dot-diameter;
      border: $border-width solid #E59E4B;
      background-color: white;
      //border-radius: 100%;
      top: 15%;
      right: -$dot-diameter;
    }

    h3 {
      margin: 0 0 10px 0;
    }

    p {
      margin: 0;
    }
  }

  .body {
    margin: 0 0 3em;
    float: right;
    width: 66%;
    padding-left: $gutter;

    > p {
      color: black;
      border-left: 5px solid #E59E4B;
      //border: 1px solid black;
      background-color: rgba(229, 158, 75, 0.25);
      padding: 10px;
      margin: 0;
      display: inline-block;

      &:first-child {
        margin-top: 0;
      }
    }

    .list {
      p {
        border-left: 3px solid #E59E4B;
        padding: 6px;
      }

      li {
        margin: .25em 0 0 0;

        &:before {
          content: "–";
          margin-right: .5em;
        }
      }
    }

    ul {
      color: #020A55;
      padding-left: 0;
      list-style-type: none;

      li {
        margin-bottom: 1.25em;

        &:before {
          //content: "–";
          margin-right: .5em;
        }

        input[type="checkbox"] {
          display: none;
        }

        label {
          text-decoration: underline;
          text-underline-offset: 5px;
          display: inline-block;
          cursor: pointer;
        }

        label:after {
          content: "▼";
        }

        input:checked ~ label:after {
          content: "▲";
        }

        .inner {
          height: 0;
          overflow: hidden;
        }

        input:checked + .inner {
          height: fit-content;
        }
      }
    }
  }
}

</style>