import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import About from "../views/About.vue";
import Portfolio from "../views/Portfolio.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "About",
        component: About,
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        component: Portfolio,
    },
    {
        path: "/contact",
        name: "Contact",
        component: About,
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
