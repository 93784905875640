<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">About</router-link>
      <router-link to="/portfolio">Portfolio</router-link>
      <router-link to="/contact">Contact</router-link>
    </div>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  margin: 0;
  background-color: #FFF8F1;
  color: #020A55;

  ::selection {
    color: #020A55;
    background: rgba(229, 158, 75, 0.5);
    border-radius: 10px;
  }
}

#nav {
  padding: 30px;
  position: sticky;
  top: 0;
  background-image: linear-gradient(#FFF8F1, rgba(255, 248, 241, 0.9));
  text-align: end;
  z-index: 1;

  a {
    margin: 0 25px;
    color: #020A55;
    font-size: 16px;
    text-decoration: none;
    user-select: none;

    &.router-link-exact-active {
      text-decoration-line: underline;
      text-underline-offset: 5px;

      &:hover {
        text-decoration-color: #020A55;
      }
    }

    &:hover {
      text-decoration-color: rgba(2, 10, 85, 0.25);
      text-decoration-line: underline;
      text-underline-offset: 5px;
    }
  }
}
</style>
<script>
import Footer from "@/components/Footer";

export default {
  components: {Footer}
}
</script>