<template>
  <footer id="footer">
    <h4>Hello</h4>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
body {
  margin: 0;
}

h4 {
  color: #FFF8F1;
}

#footer {
  margin-top: 100px;
  padding: 50px 0 50px 0;
  background-color: #020A55;
}
</style>