<template>
  <div>
    <section id="header">
      <h1>Hello, welcome to my site.<br>This is <span id="name">I-Shiang</span>.</h1>
      <blockquote>I’m a high schooler who loves chemistry<sup class="sup">[1]</sup>,
        coding<sup class="sup">[2]</sup>,
        and languages<sup class="sup">[3]</sup>.
        <br><br>
        When I was a small, little child, I used to bumble around, doing things on a whim, or because they seemed
        kinda
        cool.
        <br><br>
        Now, however, I find meaning in what I do, and do what I find meaningful.
        <br><br>
        <button>Check out my projects here</button>
      </blockquote>
      <p>
        [1]: Chemistry: my favourite science. I can live with biology. Physics... a bit too mechanical. Math’s still
        cool, though.
      </p>
      <p>
        [2]: I’ve always loved the hands-on, yet algorithmic nature of coding. Every block of code encapsulated and
        modularised, each playing their part.
      </p>
      <p>
        [3]: I first participated in a spelling bee in 2016, when I was but 11 years old. My losing word was ‘modiste’
        (of French origin). I proceeded to win 2nd runner-up the following year: misspelling ‘bouillabaisse’ this time
        (French, again). Naturally, I decided to take up French as a third language. I currently speak English,
        Mandarin
        Chinese, and French to varying degrees of proficiency.
      </p>
    </section>
    <section id="body">
      <h3>Who I am</h3>
      <p>I'm I-Shiang. Lee I-Shiang. I'm a Year 5 student at NUS High School of Mathematics & Science, Singapore.</p>
      <p>As you probably already know, chemistry, coding, and languages are cool. Combine them, though, and you get
        something even better.</p>
      <p>Check these out:</p>
      <!--      <ul>-->
      <!--        <li>Chemistry + Coding</li>-->
      <!--        <li>Languages + Coding</li>-->
      <!--        <li>Chemistry + Languages</li>-->
      <!--        <li></li>-->
      <!--      </ul>-->
      <h3>What I do</h3>
      <p></p>
    </section>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
#header, #body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#name {
  text-decoration-line: underline;
  text-underline-offset: 10px;
}

#name:hover {
  background-color: rgba(229, 158, 75, 0.75);
  cursor: pointer;
}

h1 {
  width: 60%;
  text-align: start;
  font-weight: 600;
  font-size: 36px;
  margin: 150px 0 50px 0;
  color: #0515A7;
}

blockquote {
  width: 55%;
  text-align: start;
  font-weight: 500;
  font-size: 24px;
  color: #0515A7;
  margin: 0;
  padding: 50px 10px 50px 0;
  border-right: 25px solid #E59E4B;
}

/*https://getcssscan.com/css-buttons-examples*/
button {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 20px 15px;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  touch-action: manipulation;
}

button:after {
  content: "";
  background-color: rgba(229, 158, 75, 0.75);
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

button:hover:after {
  top: 0;
  left: 0;
}

/*@media (min-width: 768px) {*/
/*  button {*/
/*    padding: 13px 50px 13px;*/
/*  }*/
/*}*/

.sup {
  font-size: 16px;
}

.sup:hover {
  background-color: rgba(229, 158, 75, 0.75);
  cursor: pointer;
}

#header p {
  width: 55%;
  text-align: end;
  font-weight: 500;
  font-size: 16px;
  color: #0515A7
}

#body h3 {
  width: 60%;
  text-align: start;
  font-weight: 600;
  font-size: 28px;
  margin: 50px 0 0 0;
  padding: 10px 0 10px 10px;
  color: #0515A7;
  border-left: 5px solid #E59E4B;
}

#body p {
  width: 60%;
  text-align: start;
  font-weight: 500;
  font-size: 20px;
}
</style>